import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
  webReportContent: {},
  requestStatus: "idle", // 'idle' | 'loading' | 'success' | 'fail',
  error: null,
};

const webReportSlice = createSlice({
  name: "webReport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWebReport.pending, (state) => {
      state.requestStatus = "loading";
    });
    builder.addCase(fetchWebReport.fulfilled, (state, action) => {
      state.requestStatus = "success";
      state.webReportContent = action.payload.report_data;
    });
    builder.addCase(fetchWebReport.rejected, (state, action) => {
      state.requestStatus = "fail";
      state.error = action.payload;
    });
  },
});

export const fetchWebReport = createAsyncThunk(
  "webReport/fetchWebReport",
  async (reportId, { rejectWithValue }) => {
    try {
      console.log("async reportId", reportId);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/compiled-reports/${reportId}/`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch web report");
      }
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default webReportSlice.reducer;
