import React from "react";
import "./webReport.css";
import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import Item from "../../components/Item";
import ConcerningLevelBadge from "../../components/ConcerningLevelBadge";

const IssueSection = ({ issueItems }) => {
  const issues = issueItems.map((issue) => {
    const hasSubsections = issue.subsections && issue.subsections.length > 0;

    const issueTypeRecord = { hazard: 0, recommendation: 0, maintenance: 0 };
    if (hasSubsections && issue.issues && issue.issues.length > 0) {
      issue.issues.forEach((issue) => {
        switch (issue.concern_level) {
          case 0:
            issueTypeRecord.maintenance++;
            break;
          case 1:
            issueTypeRecord.recommendation++;
            break;
          case 2:
            issueTypeRecord.hazard++;
            break;
          default:
            break;
        }
      });
    }

    const totalIssues = Object.values(issueTypeRecord).reduce(
      (acc, curr) => acc + curr,
      0
    );

    return (
      <div key={issue.name}>
        <Title
          text={issue.name}
          hasSubsections={hasSubsections}
          issueTypeRecord={issueTypeRecord}
          totalIssues={totalIssues}
          placedAt="issueSection"
        />
        {issue?.subsections.map((subsection) => {
          const issueTypeRecord = {
            hazard: 0,
            recommendation: 0,
            maintenance: 0,
          };
          if (subsection?.issues) {
            subsection.issues.forEach((issue) => {
              const concernLevel = issue?.concern_level;
              switch (concernLevel) {
                case 0:
                  issueTypeRecord.maintenance++;
                  break;
                case 1:
                  issueTypeRecord.recommendation++;
                  break;
                case 2:
                  issueTypeRecord.hazard++;
                  break;
                default:
                  break;
              }
            });
          }
          const totalIssues = Object.values(issueTypeRecord).reduce(
            (acc, curr) => acc + curr,
            0
          );
          return (
            <div key={subsection.name}>
              <SubTitle
                text={subsection.name}
                issueTypeRecord={issueTypeRecord}
                totalIssues={totalIssues}
                placedAt="issueSection"
              />
              {subsection?.issues.map((issue, index) => {
                const issueContent = (
                  <div className="issue-content-wrapper">
                    <div className="issue-content-row">
                      <span className="label issue-field-label">
                        Description
                      </span>
                      <span className="issue-field-content">
                        {issue.description}
                      </span>
                    </div>
                    <div className="issue-content-row">
                      <span className="label issue-field-label">Cause</span>
                      <span className="field-content issue-field-content">
                        {issue.cause}
                      </span>
                    </div>
                    <div className="issue-content-row">
                      <span className="label issue-field-label">Solution</span>
                      <span className="field-content issue-field-content">
                        {issue.solution}
                      </span>
                    </div>
                    <div className="issue-content-row">
                      <span className="label issue-field-label">Code</span>
                      <span className="field-content issue-field-content">
                        <span style={{ display: "inline-block" }}>
                          {issue["code_selected"]?.code || issue["code_1"].code}
                        </span>
                        <span style={{ display: "inline-block" }}>
                          {issue["code_selected"]?.content ||
                            issue["code_1"].content}
                        </span>
                      </span>
                    </div>
                    {/* Add the images section */}
                    {issue.images && issue.images.length > 0 && (
                      <div className="issue-content-row">
                        <div className="issue-images-grid">
                          {issue.images.map((image) => (
                            <div key={image.id} className="image-container">
                              <img
                                src={image.presigned_url}
                                alt={`Issue ${issue.name} - ${image.order}`}
                                className="issue-image"
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                );
                return (
                  <div
                    key={issue.name || issue.id}
                    id={issue.id}
                    className="issue-container"
                  >
                    <Item
                      text={issue.name || issue.id}
                      children={issueContent}
                      showBadge
                      badgeContent={
                        <ConcerningLevelBadge
                          concerningLevel={issue.concern_level}
                        />
                      }
                      placedAt="issueSection"
                      showDivider={index !== subsection.issues.length - 1}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  });
  console.log("issue section items", issueItems);
  return <div className="issue-section-container">{issues}</div>;
};

export default IssueSection;
