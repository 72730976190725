import React from "react";

const Item = ({
  id,
  text,
  children,
  showDivider = true,
  showBadge = false,
  badgeContent,
}) => {
  return (
    <div id={id}>
      <div className="item">
        {text}
        {showBadge && badgeContent}
      </div>
      {children}
      {showDivider && <hr />}
    </div>
  );
};

export default Item;
