import React from "react";
import Drawer from "../../components/Drawer";
import "./webReport.css";

const LeftNavigation = ({ metaDataItems, issueItems, title }) => {
  const toggableLevels = ["sections", "subsections", "issues"];
  return (
    <Drawer
      title={title}
      metaDataContent={metaDataItems}
      issueContent={issueItems}
      toggableLevels={toggableLevels}
    />
  );
};

export default LeftNavigation;
