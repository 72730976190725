import React from "react";
import { Badge } from "react-bootstrap";
import { ConcerningLevelLibrary } from "../containers/WebReport/helperFunctions";

const ConcerningLevelBadge = ({ concerningLevel }) => {
  const concerningLevelData = ConcerningLevelLibrary[concerningLevel] || {};

  return (
    <Badge className={`concern-level-badge ${concerningLevelData.colorRule}`}>
      {concerningLevelData.name}
    </Badge>
  );
};

export default ConcerningLevelBadge;
