import React from "react";
import Circle from "./Circle";

const Title = ({
  id,
  text,
  hasSubsections = false,
  totalIssues = 0,
  issueTypeRecord = {},
  placedAt = "",
}) => {
  return (
    <div className="title-wrapper">
      <div id={id} className="title">
        {text}
        {!hasSubsections && placedAt === "issueSection" && (
          <div className="d-flex align-items-center" style={{ gap: "8px" }}>
            <div>
              {issueTypeRecord.hazard > 0 ? (
                <Circle type="concern-hazard" />
              ) : null}
              {issueTypeRecord.recommendation > 0 ? (
                <Circle type="concern-recommendation" />
              ) : null}
              {issueTypeRecord.maintenance > 0 ? (
                <Circle type="concern-maintenance" />
              ) : null}
            </div>
            <span className="total-issues">
              {totalIssues} issue{totalIssues > 1 ? "s" : ""}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Title;
