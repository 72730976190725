import React, { useState, useEffect } from "react";
import { Nav, Accordion } from "react-bootstrap";
import "./drawer.css";
import Circle from "../Circle";
import Title from "../Title";
import { ConcerningLevelLibrary } from "app/containers/WebReport/helperFunctions";

const Drawer = ({ title, metaDataContent, issueContent }) => {
  // Initializing all top-level sections to be open
  const initialActiveKeys = issueContent.map((section, index) => {
    return section?.subsections?.length > 0
      ? `accordion-${section.name}`
      : null;
  });

  const [sectionActiveKeys, setSectionActiveKeys] = useState(initialActiveKeys);
  const [subsectionActiveKeys, setSubsectionActiveKeys] = useState([]);

  // Function to toggle an accordion section
  const toggleAccordion = (key, isSubsection = false) => {
    if (isSubsection) {
      setSubsectionActiveKeys((prev) =>
        prev.includes(key) ? prev.filter((k) => k !== key) : [...prev, key]
      );
    } else {
      setSectionActiveKeys((prev) =>
        prev.includes(key) ? prev.filter((k) => k !== key) : [...prev, key]
      );
    }
  };

  /* scroll-spy */
  const [activeSection, setActiveSection] = useState("project information");

  useEffect(() => {
    if (!activeSection) setActiveSection("project information");

    const handleScroll = () => {
      const sections = document.querySelectorAll(
        ".issue-container, .metadata-container"
      );
      let currentSection = "";

      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 0 && rect.bottom >= 10) {
          currentSection = section.id;
        }
      });

      if (currentSection) {
        setActiveSection(currentSection);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // check on mount in case user is already scrolled

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  /* Scroll and Toggle open the active section */
  useEffect(() => {
    if (!activeSection) return;

    const activeIssueNavItem = document.getElementById(
      `nav-item-${activeSection}`
    );
    if (!activeIssueNavItem) return;

    activeIssueNavItem.scrollIntoView({ behavior: "smooth" });

    // Find both the subsection and section names
    let foundSubsection = null;
    let foundSection = null;

    issueContent.forEach((section) => {
      section.subsections.forEach((subsection) => {
        const issue = subsection.issues.find(
          (issue) => String(issue.id) === String(activeSection)
        );
        if (issue) {
          foundSubsection = subsection;
          foundSection = section;
        }
      });
    });

    if (foundSection) {
      setSectionActiveKeys((prev) => {
        const sectionKey = `accordion-${foundSection.name}`;
        return prev.includes(sectionKey) ? prev : [...prev, sectionKey];
      });
    }

    if (foundSubsection) {
      setSubsectionActiveKeys((prev) => {
        const subsectionKey = `accordion-${foundSubsection.name}`;
        return prev.includes(subsectionKey) ? prev : [...prev, subsectionKey];
      });
    }
  }, [activeSection, issueContent]);

  /* UI components */
  const metaDataNavItems = Object.keys(metaDataContent).map((item, index) => {
    return (
      <Nav key={item} className="metadata-nav-item">
        <div
          id={`nav-item-${item.toLowerCase()}`}
          className={`mx-2 w-100 ${
            activeSection === item.toLowerCase() ? "nav-item-active" : ""
          }`}
        >
          <Nav.Link id={item.toLowerCase()} href={`#${item.toLowerCase()}`}>
            <div className="d-flex justify-content-between w-100">{item}</div>
          </Nav.Link>
        </div>
        <hr className="w-100 m-2" />
      </Nav>
    );
  });

  const issueContentArray = issueContent.map((section, index) => {
    const hasSubsections =
      section.subsections && section.subsections.length > 0;

    const issueTypeRecord = { hazard: 0, recommendation: 0, maintenance: 0 };
    if (!hasSubsections && section?.issues?.length > 0) {
      section.issues.forEach((issue) => {
        switch (issue.concern_level) {
          case 0:
            issueTypeRecord.maintenance++;
            break;
          case 1:
            issueTypeRecord.recommendation++;
            break;
          case 2:
            issueTypeRecord.hazard++;
            break;
          default:
            break;
        }
      });
    }

    const totalIssues = Object.values(issueTypeRecord).reduce(
      (acc, curr) => acc + curr,
      0
    );

    const mostSeriousIssue =
      issueTypeRecord.hazard > 0
        ? "concern-hazard"
        : issueTypeRecord.recommendation > 0
        ? "concern-recommendation"
        : "concern-maintenance";

    return (
      <Accordion.Item eventKey={`accordion-${section.name}`} key={section.name}>
        <Accordion.Header
          onClick={() => toggleAccordion(`accordion-${section.name}`)}
        >
          <div className="d-flex justify-content-between w-100">
            {section.name}
            {!hasSubsections ? (
              <Circle type={mostSeriousIssue} text={totalIssues} />
            ) : null}
          </div>
        </Accordion.Header>
        {hasSubsections && (
          <Accordion.Body>
            {section.subsections.map((subsection) => {
              // show circle on subsection
              const issueTypeRecord = {
                hazard: 0,
                recommendation: 0,
                maintenance: 0,
              };
              if (subsection?.issues) {
                subsection.issues.forEach((issue) => {
                  const concernLevel = issue?.concern_level;
                  switch (concernLevel) {
                    case 0:
                      issueTypeRecord.maintenance++;
                      break;
                    case 1:
                      issueTypeRecord.recommendation++;
                      break;
                    case 2:
                      issueTypeRecord.hazard++;
                      break;
                    default:
                      break;
                  }
                });
              }
              const totalIssues = Object.values(issueTypeRecord).reduce(
                (acc, curr) => acc + curr,
                0
              );

              const mostSeriousIssue =
                issueTypeRecord.hazard > 0
                  ? "concern-hazard"
                  : issueTypeRecord.recommendation > 0
                  ? "concern-recommendation"
                  : "concern-maintenance";

              return (
                <Accordion
                  key={subsection.name}
                  className="flex-column subsection-accordion-container"
                  activeKey={subsectionActiveKeys}
                >
                  <Accordion.Item eventKey={`accordion-${subsection.name}`}>
                    <Accordion.Header
                      onClick={() =>
                        toggleAccordion(`accordion-${subsection.name}`, true)
                      }
                    >
                      <div className="d-flex justify-content-between w-100">
                        {subsection.name}
                        <Circle type={mostSeriousIssue} text={totalIssues} />
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      {subsection.issues.map((issue) => {
                        return (
                          <div
                            key={issue.id}
                            id={`nav-item-${issue.id}`}
                            className={`ps-0 pe-3 ${
                              activeSection === String(issue.id)
                                ? "issue-nav-item-active"
                                : ""
                            }`}
                          >
                            <Nav.Link
                              id={issue.id}
                              href={`#${issue.id}`}
                              key={issue.name || issue.order}
                            >
                              <div className="d-flex justify-content-between w-100">
                                {issue.name || issue.order}
                                <Circle
                                  type={
                                    ConcerningLevelLibrary[issue.concern_level]
                                      .colorLabel
                                  }
                                  text={null}
                                  smallTopMargin={false}
                                />
                              </div>
                            </Nav.Link>
                          </div>
                        );
                      })}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              );
            })}
          </Accordion.Body>
        )}
        {/* {!hasSubsections && section?.issues?.length > 0 && (
          <Accordion.Body>
            {section.issues.map((issue) => {
              return (
                <Nav.Link
                  id={`nav-item-${issue.id}`}
                  key={issue.name || issue.order}
                  className="p-2 ps-2"
                >
                  <div className="d-flex justify-content-between w-100">
                    {issue.name || issue.order}
                    <Circle
                      type={
                        ConcerningLevelLibrary[issue.concern_level].colorLabel
                      }
                      text={null}
                      smallTopMargin={false}
                    />
                  </div>
                </Nav.Link>
              );
            })}
          </Accordion.Body>
        )} */}
      </Accordion.Item>
    );
  });

  return (
    <div className="drawer">
      <div className="drawer-title-container d-none d-lg-block px-2">
        <Title text={title} />
      </div>
      {metaDataNavItems}
      <Accordion
        className="section-accordion-container"
        activeKey={sectionActiveKeys}
      >
        {issueContentArray}
      </Accordion>
    </div>
  );
};

export default Drawer;
