import React from "react";
import "./webReport.css";
import Item from "../../components/Item";
import { formattedDate } from "./helperFunctions";
import Title from "../../components/Title";

const LABEL_DEFINITION = {
  inspector: {
    type: "short-label",
    displayName: "Inspector",
  },
  inspectedDate: {
    type: "short-label",
    displayName: "Inspected Date",
  },
  name: {
    type: "short-label",
    displayName: "Name",
  },
  info: {
    type: "long-field-content",
    displayName: "Note",
  },
  apn: {
    type: "long-label",
    displayName: "Parcel Number",
  },
  address: {
    type: "long-field-content",
    displayName: "Address",
  },
  city: {
    type: "short-label",
    displayName: "City",
  },
  state: {
    type: "short-label",
    displayName: "State",
  },
  zipCode: {
    type: "short-label",
    displayName: "Postal Code",
  },
  year_build: {
    type: "short-label",
    displayName: "Year Built",
  },
  stories: {
    type: "long-label",
    displayName: "Number of Stories",
  },
  floor_area: {
    type: "short-label",
    displayName: "Square Feet",
  },
  report_type: {
    type: "short-label",
    displayName: "Report Type",
  },
  house_type: {
    type: "short-label",
    displayName: "House Type",
  },
};

const FIELDS_PER_ROW = [2, 3, 3, 2]; // Adjust this number as needed

const MetaDataSection = ({ metaDataItems }) => {
  const { "Project Information": projectInfo } = metaDataItems;
  const {
    inspectorName,
    name,
    info: note, // TODO: confirm if API-returned 'info' is note
    created_at: createdDate,
    propertyDetails,
    summary,
  } = projectInfo;

  const InspectionDetailsSection = (
    <div className="info-row">
      <div className="field short-label">
        <span className="label">Inspector</span>
        <span className="field-content">
          {inspectorName || "{Inspector Name}"}{" "}
        </span>
      </div>
      <div className="field long-label">
        <span className="label">Inspected Date</span>
        <span className="field-content">{formattedDate(createdDate)}</span>
      </div>
    </div>
  );

  const ClientDetailsSection = (
    <div className="info-row">
      <div className="field short-label">
        <span className="label">Name</span>
        <span className="field-content">{name || "{Client Name}"} </span>
      </div>
      <div className="field long-field-content">
        <span className="label">Note</span>
        <span className="field-content">{note}</span>
      </div>
    </div>
  );

  let currentRowIndex = 0;
  const PropertyDetails = propertyDetails
    .filter((item) => item.value)
    .reduce((rows, item) => {
      // Create new row array if it doesn't exist
      if (!rows[currentRowIndex]) {
        rows[currentRowIndex] = [];
      }

      // Add the field to the current row
      rows[currentRowIndex].push(
        <div
          key={item.key}
          className={`field ${LABEL_DEFINITION[item.key]?.type}`}
        >
          <span className="label">
            {LABEL_DEFINITION[item.key]?.displayName}
          </span>
          <span className="field-content">{item.value}</span>
        </div>
      );

      if (rows[currentRowIndex].length === FIELDS_PER_ROW[currentRowIndex]) {
        currentRowIndex++;
      }

      return rows;
    }, []);

  const PropertyDetailsSection = (
    <>
      {PropertyDetails.map((row, index) => (
        <div key={index} className="info-row">
          {row}
        </div>
      ))}
    </>
  );

  const OverviewSection = <div className="info-row">{summary}</div>;

  return (
    <>
      <div className="metadata-container" id="project information">
        <Title id={"project information"} text={"Project Information"} />
        <Item text="Inspection Details" children={InspectionDetailsSection} />
        <Item text="Client Details" children={ClientDetailsSection} />
        <Item text="Property Details" children={PropertyDetailsSection} />
      </div>
      <div className="metadata-container" id="overview">
        <Item text="Overview" children={OverviewSection} showDivider={false} />
      </div>
    </>
  );
};

export default MetaDataSection;
