import { Routes, Route } from "react-router-dom";
import "./App.css";
import { useAppDispatch } from "./utils/redux/hooks";
import ProjectInformation from "./app/containers/ProjectInformation";
import WebReport from "./app/containers/WebReport";

function App() {
  const dispatch = useAppDispatch();

  const IndexView = (
    <div className="project-info-wrapper">
      <div className="default-header">
        <h1 className="header-title">Report.Gen</h1>
      </div>
      <ProjectInformation />
    </div>
  );

  return (
    <>
      <Routes>
        <Route path="/" element={IndexView} />
        <Route path="/compiled-reports/:reportId" element={<WebReport />} />
      </Routes>
    </>
  );
}

export default App;
