import React from "react";
import "./webReport.css";
import MetaDataSection from "./metaDataSection";
import IssueSection from "./issueSection";

const ReportContent = ({ metaDataItems, issueItems, title }) => {
  return (
    <div className="main-content">
      <div>
        <MetaDataSection metaDataItems={metaDataItems} />
        <IssueSection issueItems={issueItems} />
      </div>
    </div>
  );
};

export default ReportContent;
