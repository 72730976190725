import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Offcanvas, Button } from "react-bootstrap";

import { useParams } from "react-router-dom";
import { fetchWebReport } from "./slice";
import LeftNavigation from "./leftNavigation";
import ReportContent from "./reportContent";
import "./webReport.css";
import burgerMenu from "../../components/Images/burger-menu.svg";
const WebReport = () => {
  // For mobile view
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();

  const { webReportContent, requestStatus } = useSelector(
    (state) => state.webReport
  );

  const { reportId } = useParams();

  useEffect(() => {
    // Initialize component, fetch data, etc.
    dispatch(fetchWebReport(reportId));
  }, [dispatch, reportId]);

  // update the title of the page
  useEffect(() => {
    document.title =
      webReportContent?.address?.formatted_address +
        " - " +
        webReportContent?.name || "Web Report";
  }, [webReportContent]);

  if (requestStatus !== "success") {
    return <div>Loading...</div>;
  }

  const {
    address,
    apn,
    created_at,
    floor_area,
    house_type,
    id,
    info,
    name,
    sections,
    stories,
    updated_at,
    user,
    year_build,
    summary,
  } = webReportContent;

  const metaDataItems = {
    "Project Information": {
      created_at,
      id,
      info,
      name,
      updated_at,
      user,
      summary,
      propertyDetails: [
        {
          key: "address",
          value: `${address.address_line1 || ""} ${
            address.address_line2 || ""
          }`,
        },
        {
          key: "city",
          value: address.locality || "",
        },
        {
          key: "state",
          value: address.administrative_area || "",
        },
        {
          key: "zipCode",
          value: address.postal_code || "",
        },
        {
          key: "year_build",
          value: year_build,
        },
        {
          key: "floor_area",
          value: floor_area,
        },
        {
          key: "stories",
          value: stories,
        },
        {
          key: "house_type",
          value: house_type,
        },
        {
          key: "apn",
          value: apn,
        },
        {
          key: "info",
          value: info,
        },
      ],
    },
    Overview: {},
  };
  const issueItems = sections || [];

  const navigationHeader =
    metaDataItems?.["Project Information"]?.propertyDetails?.find(
      (field) => field.key === "address"
    ).value +
      " - " +
      metaDataItems?.["Project Information"]?.name || "Web Report";

  return (
    <div className="web-report-container">
      <div className="button-container d-lg-none">
        <Button variant="primary" onClick={handleShow}>
          <div>
            <img src={burgerMenu} alt="burger menu" />
          </div>
          <div className="top-title-truncate">{navigationHeader}</div>
        </Button>
      </div>

      <Offcanvas
        show={show}
        onHide={handleClose}
        responsive="lg"
        scroll={true}
        className=""
      >
        <Offcanvas.Body>
          <LeftNavigation
            metaDataItems={metaDataItems}
            issueItems={issueItems}
            title={navigationHeader}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <ReportContent metaDataItems={metaDataItems} issueItems={issueItems} />
    </div>
  );
};

WebReport.propTypes = {
  // Define prop types here
};

export default WebReport;
