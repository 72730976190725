export const formattedDate = (date) => {
  return date ? new Date(date).toISOString().split("T")[0] : "";
};

export const ConcerningLevelLibrary = {
  0: {
    name: "Maintenance Item",
    colorRule: "concerning-level-maintenance",
    colorLabel: "concern-maintenance",
  },
  1: {
    name: "Recommendation",
    colorRule: "concerning-level-recommendation",
    colorLabel: "concern-recommendation",
  },
  2: {
    name: "Safety Hazard",
    colorRule: "concerning-level-safety-hazard",
    colorLabel: "concern-hazard",
  },
};
