import { configureStore, combineReducers } from "@reduxjs/toolkit";
import counterReducer from "../../example/counter/counterSlice";
import projectInfoReducer from "../../app/containers/ProjectInformation/slice";
import issueReducer from "../../app/containers/Issue/slice";
import { streamingProjectInfoMiddleware } from "../../app/containers/ProjectInformation/streamingProjectInfoMiddleware";
import { streamingIssueMiddleware } from "../../app/containers/Issue/streamingIssueMiddleware";
import webReportReducer from "../../app/containers/WebReport/slice";

const rootReducer = combineReducers({
  counter: counterReducer,
  projectInfo: projectInfoReducer,
  issue: issueReducer,
  webReport: webReportReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      streamingProjectInfoMiddleware,
      streamingIssueMiddleware,
    ]),
});

export function setupStore(preloadedState) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}
